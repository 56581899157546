

.contenido-carrusel-2 {
    margin: 50px auto;
    width: 90%;
}

.contenedor-carusel {
    width: 100%;
    // height: 180px;
    height: 220px;
    margin-bottom: 50px;
}

.swiper-wrapper {
    align-items: center;
}


.title-carousel {
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
}


.card-carrusel {
    width: 100%;
    height: 180px !important;
    // border: 1px solid red;
    transition: .3s ease all;
    position: relative;
}


.img-carrusel2 {
    width: 100%;
    height: 100% !important;
}


.img-carrusel2 img {
    width: 100%;
    height: 100% !important;
}


.card-carrusel:hover {
    transform: scale(1.5, 1.5) !important;
    transform-origin: center;
    z-index: 999;
}



.title-carrusel {
    font-size: 12px;
    // position: absolute;
    // top: 0;
    // color: #000000;
    text-align: center;
}