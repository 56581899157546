/* Stilos de Header */
.header {
    width: 100%;
    height: 90vh;
}


.logo {
    display: flex;
    align-items: center;
    color: white;
}


.barra {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background: rgba(9,9,9,0.5); 
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2000;
}


.logo {
    padding-left: 40px;
    height: 100%;
    width: 130px;
}

.logo  img {
    width: 90px;
}


.menu-links {
    height: 100%;
    transition: transform .5s;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.menu-item {
    list-style: none;
    position: relative;
    height: 100%;
    --clip: polygon(0 0, 100% 0, 100% 0, 0 0);
}


.menu-item:hover {
    --clip: polygon(0 0, 100% 0, 100%, 0% 100%);
    cursor: pointer !important;
}



.menu-nesting {
    clip-path: var(--clip);
}

.menu-link {
    color: #fff;
    text-decoration: none;
    padding: 0 20px;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-family: 'Oswald', sans-serif !important;
}



.menu-ingreso {
    background-color: $primario;
    height: 50px;
    align-self: center !important;
    border-radius: 5px;
    margin-left: auto;
    margin-right: 20px;
    text-align: center;
}


.btn-enlace {
    width: 100%;
    background: #000000;
    border: none;
}


.menu-link:hover {
    color: gray;
    // background: $segundario;
}


.menu-nesting {
    list-style: none;
    transition: clip-path .3s;
    clip-path: var(--clip);
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding-left: 0px;
    width: 100%;
    transform: translateY(100%);
    background: #000000;
    text-align: center;
}


.link-inside {
    padding: 15px;
    border-bottom: 1px solid white;
    font-size: 13px;
    text-align: center;
    background: #000000;
}


.menu-hamburguesa {
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    cursor: pointer;
    display: none;
}

.menu-icon {
    display: block;
    color: #fff;
    font-size: 25px;

}



@media (max-width: 920px){

    .carrito2 {
        right: 100px !important;
        top: 25px;
    }

    .menu-hamburguesa {
        display: flex;
    }


    .menu-item {
        --clip: 0;
        overflow: hidden;
    }

    .menu-active {
    //    --transform: rotate(0);
        --background: #5e7094;
    }


    .menu-links {
        position: fixed;
        max-width: 300px;
        width: 100%;
        top: 70px;
        bottom: 0;
        right: 0;
        background: #000;
        transform: translateX(100%);
        margin: 0px;
        padding: 0px;
        overflow-y: auto !important;
        grid-auto-columns: max-content;
        display: grid;
        grid-auto-rows: max-content;
        padding-top: 40px;
        padding-bottom: 70px;
    }


    .menu-item {
        width: 150%;
    }


    .menu-link {
        padding: 25px;
        padding-left: 30px;
        height: auto;
        width: 100% !important;
    }


    .menu-nesting {
        display: grid;
        position: unset;
        width: 100%;
        transform: translateY(0);
        height: 0;
        transition: height .3s;
    }

    .menu-links-show {
        transform: unset;
        width: 100%;
    }


    .menu-ingreso {
        background-color: $primario;
        height: unset;
        align-self: none;
        border-radius: 5px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .link-inside {
        border: none;
        color: $segundario !important;
    }

}


