
    .modalimg {
        background: #000000;
        border-radius: 10px;
        color: #fff;
        height: 500px;
        width: 700px;
        padding: 20px;
    }

