.footer {
    width: 100%;
    min-height: 400px; 
    background: #000000;
    color: white;
    padding: 0.1px;
    padding-top: 40px;
    margin-bottom: 0px;
    border-top: 1px solid #3f3b3a;
}

.icon {
    text-align: center;
    margin: 30px 0;
}

.icon img{
    width: 120px;
}


.informacion {
    display: flex;
    justify-content: space-around;
    min-height: 150px;
}


.redes {
    // display: flex;
    // justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
}

.icn {
    width: 35px;
    height: 35px;
    border: 1px solid white;
    border-radius: 50%;
    align-self: center;
    line-height: 35px;
    font-size: 20px;
    color: white;
}

.inpar {
    margin-right: 10px;
}


.redes .par:hover {
    background: white;
    color: $primario;
}

.redes .inpar:hover {
    background: white;
    color: $segundario;
}


.oficinas {
    display: flex;
    flex-direction: column;
}


.ofi {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 5px;
    width: 32%;
}

.ofi h2 {
    text-align: center;
    font-weight: bold;
    font-size: 20px !important;
    letter-spacing: 5px;
    // border-bottom: 1px solid white;
    // padding-bottom: 10px;
    width: 80%;
    margin-bottom: 0px;
    font-size: 30px;
}

.ofi small {
    font-size: 16px;
    font-weight: lighter;
}

// .celular, .telefono {
//     font-size: 20px;
//     margin-right: 10px;
// }

// .celu, .fijo{
//     width: 30px;
//     height: 30px;
//     border: 1px solid white;
//     border-radius: 50%;
//     text-align: center;
//     line-height: 30px;
//     margin-right: 10px;
// }

// .correo {
//     background: transparent;
//     color: white;
//     margin-top: 8px;
//     padding: 10px;
//     border-radius: 5px;
//     font-size: 17px;
// }

// .correo:hover {
//     background: #343a40;
// }

.privacidad {
    border-top: 1px solid white;
    padding: 0.1px;
}

// .politicas {
//     display: flex;
//     justify-content: space-evenly;
//     margin: 15px 0;
// }


// .item-politica a{
//     text-decoration: none;
//     color: white;
//     font-size: 15px;
// }

.fin { 
    border-top: 1px solid white;
    text-align: center;
    margin-top: 0px;
}

.copy {
    font-size: 18px;
    margin-top: 20px;
}



@media (max-width: 620px){
    .informacion {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 20px auto;
    }

    .ofi {
        width: 100%;
        margin-top: 30px;

    }
}
