
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Irish+Grover&family=Oswald:wght@500&display=swap');


* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
}


html, body {
    background: #000000 !important;
}


.comprar-role * {
    font-family: 'Oswald', sans-serif !important;
}


.verop {
    cursor: pointer;
}


// Estilos Para los Modales en general
.style-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    padding: 35px;
    width: 500px;
    background: #424242;
    border-radius: 20px;
    // min-height: 400px;
}













//! Pendiente
.formTodo {
    font-family:'Roboto';
    width: 90%; 
    margin:0px auto; 
    margin-top: 10px;
    margin-bottom: 70px;
    display:block; 
    padding: 20px;
}


.formTodoOp {
    font-family:'Roboto';
    width: 95%; 
    margin:0px auto; 
    margin-top: 10px;
    margin-bottom: 70px;
    display:block; 
    padding: 20px;
}


.boton-file {
    display: none;
}


// Botones de Estado
.boton-estado {
    border: 3px solid #707070 !important;
}

.trru{
    background-color: #049336 !important;
}

.fall{
    background-color: #C10707 !important;
}


// Estilos para Celdas en tabla
.w-100 {
    width: 100px !important;
}

.w-150 {
    width: 150px;
}

.w-200 {
    width: 200px;
}

.w-220 {
    width: 220px;
}

.w-350 {
    width: 350px;
}

.w-300 {
    width: 300px;
}
// Final de Estilos para Celdas en tabla 




div:where(.swal2-container).swal2-center {
    z-index: 2000 !important; 
}

/* Estilos para el scrollbar */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}


// Estilos de Contactanos
.list-contactos li {
    color: #fff;
    text-align: center;
    list-style: none;
    padding: 5px 0;
    font-size: 20px;
    font-weight: lighter;
}
.icono-red:hover {
    color: #51CF19;
}


.enlace-contac:hover {
    color: #51CF19;
}



.img-contac {
    mask-image: linear-gradient(
        #000000 80%,
        transparent,
    );
}

.input-carrito .css-myb2s4-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 5px !important;
    text-align: center !important;
    border: none !important;
}


.img-coti {
    margin: 0 auto;
}





























input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }



// Estilos de Imagen en Modal
.img-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.img-modal img {
    width: 100%;
    height: 100%;
}


// Imagen de Productos en Actualizar
.imagen-user {
    width: 30%;
    // height: 300px;
    margin-left: 20px;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
}


.contenedor-image {
    width: 90%;
    height: 250px;
    border: 1px solid blue;
    margin: 0 auto;
    margin-bottom: 50px;
}


.contenedor-image img {
    width: 100%;
    height: 100%;
}




// Estilos en la tarjetas de categorias
.style-modal2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    width: 60%;
    text-align: center;
    padding: 20px!important;
    background: rgba(30, 30, 30, 0.7);
    border-radius: 20px;
    // min-height: 400px;
}




.david {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 30px;
    color: gray;
    font-size: 12px;
}





//! Pendiente





.botonUpload {
    background: $segundario;
    color: #fff;
    font-size: 17px;
}


.comprar-role {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    // border: 4px solid pink !important;
}


.fin-carrito {
    // border-top: 1px solid gray;
    width: 100%;
    text-align: center;
}



.comprar-dos {
    width:100%;
    background-color: rgba( 150, 150, 150, 0.5);
    color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 0.1px;
}

.comprar-dos h2 {
    font-size: 22px;
    padding: 15px;
    border-bottom: 1px solid #fff;
    text-align: center;
}

.comprar-orden {
    padding: 10px;
    width: 100%;
}



.total-orden {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}


.total-orden .com-total {
    font-size: 18px;
    font-weight: bold;
}


.total-orden .total-precio {
    font-size: 18px;
    font-weight: bold;
}



// Login
.login-cliente {
	display: flex;
	flex-direction: column !important;
	min-height: 100vh;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.img-cliente {
	width: 300px;
	margin-top: 40px
}

.img-cliente img {
	width: 100%;
}


.underline:hover {
    color: blue;
    text-decoration: underline;
}



.icon-boton {
    width: 27px;
    height: 27px;
    // margin-right: 5px !important;
}



.parrafo-detalles {
    color: #fff;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
}



.atras {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto;
    box-sizing: border-box;
    padding-right: 10px;
}

.atras button i {
    font-size: 40px;
}

.a100 {
    width: 100%;
}





