

.contenedor-principal {
    width: 100%;
    height: 90vh;
}



#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}



body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
  // background: #000000;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.swiper-button-next, .swiper-button-prev {
  background: rgba(100, 100, 100, .5);
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  text-align: center;
}


.swiper-button-next.swiper-button-disabled, 
.swiper-button-prev.swiper-button-disabled {
  pointer-events: all !important;
}


.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 25px !important;
  font-weight: bold;
  color: $primario;  
  text-align: center;
}