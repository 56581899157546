

/* Modal */
.ReactModalPortal > div{
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 999;
}

.modal-fondo {
    // background-color: rgba(0, 0, 0, 0.3);
    background-color: rgba(200, 200, 200, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 9999 !important;

}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal {
    background: #000000;
    border-radius: 10px;
    color: #fff;
    display: inline;
    max-height: 100vh;
    max-width: 400px;
    outline: none;
    position: relative;
    overflow-y: scroll;
}


.title-carrito {
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid gray;
}


.img-carrito {
    width: 30%;
}

.img-carrito img {
    width: 100%;
    padding: 10px;
}


.body-carrito {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 70%;
}


.nombre-carrito {
    display: flex;
    justify-content: space-between;
}


.nombre-carrito h2 {
    font-size: 14px;
}

.comprar-eliminar i {
    font-size: 17px;
    color: #fff;
    &:hover {
        color: red
    }
}



.cantidad-carrito input {
    height: 40px;
    width: 80px;
}



.boton-modal {
    color: #fff;
    background: $primario;
    display: block;
    width: 80%;
    margin: auto;
    margin-top: 40px
}





.fin-carrito h4 {
    text-align: left;
}

.fin-carrito h6 {
    text-align: left;
    color: $primario;
}


.fin-carrito button {
    width: 70%;
    margin-top: 20px;
    background: $segundario;
    color: #fff;
}

.precios h6 {
    color: $primario;
}